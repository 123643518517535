.converted{
    .module-title{
        display: flex;
        align-items: center;
        color: #000;
        font-size: 16px;
    }
    .hashrate-hosting{
        .amount{
            margin:15px 0 0;
            font-size: 36px;
            font-weight: 700;
        }
        .btc-amount{
            color: #a8a8a8;
            font-size: 14px;
        }
    }
    .owner-box{
        width: 400px;
        margin-top: 25px;
        padding: 25px;
        box-sizing: border-box;
        border: 1px solid #000;
        border-radius: 8px;
        .name-box{
            display: flex;
            align-items: center;
            justify-content: space-between;
            .bit-box{
                display: flex;
                align-items: center;
                .bitBTC{
                    font-size: 28px;
                    color: #ff9b22;
                }
                .coinname{
                    margin-left: 10px;
                    font-size: 12px;
                    font-weight: 900;
                    color: #ff9b22;
                }
            }
            .auto-pay{
                color: #787878;
                font-size: 12px;
            }
            
        }

        .owner-bottom{
            display: flex;
            justify-content: space-between;
            margin-top: 20px;
            .latest-con, .owned-th-lab{
                color: #a8a8a8;
                font-size: 13px;
                margin-bottom: 13px;
            }
            .latest-btc,.owned-th{
                color: #000;
                font-size: 16px;
                font-weight: 500;
            }
        }
        
    }
}
