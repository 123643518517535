.deposit-box{
    width: 400px;
    margin-top: 30px;
    padding: 25px 30px;
    box-sizing: border-box;
    border-radius: 8px;
    border: 1px solid #ececec;
    .deposit-addr{
        display: flex;
        align-items: center;
        margin-top: 20px;
        font-size: 12px;
        .bitfuzhi{
            margin-left: 10px;
            font-size: 26px;
        }
    }
}
