.use-coupon-box{
    display: flex;
    position: relative;
    width: 370px;
    height: 140px;
    margin:10px 10px 0 0;
    padding: 35px 5px 0;
    box-sizing: border-box;
    background: url(../../assets/pay-coupon.png) no-repeat;
    background-size: 100%;
    line-height: 1;
    text-align: center;
    cursor: pointer;
    .use-coupon-left{
        width: 140px;
        .amount{
            margin-bottom: 10px;
            color: #c00;
            font-family: Helvetica;
            font-size: 24px;
            font-weight: 700;
        }
        .condition{
            margin-top: 14px;
            font-size: 12px;
            color: #666;
        }
    }
    .use-coupon-right{
        flex: 1;
        .use-ruler{
            color: #666;
            font-size: 14px;
            line-height: 24px;
            /* transition: all 0.2s;
            cursor: pointer; */
        }
        /* .use-ruler:hover{
            color: #c00;
            transition: all 0.2s;
        } */
        .use-date{
            margin-top: 14px;
            font-size: 12px;
            color: #666;
        }
        
    }  
}
.use-coupon-box.selected{
    background: url(../../assets/pay-coupon-selected.png) no-repeat;
    background-size: 100%;
}