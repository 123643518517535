.login{
    .sign-btn{
        /* margin: 0 10px; */
        height: 48px;
        cursor: pointer;
        color: #000;
        font-size: 15px;
        font-weight: 500;
    }
    .sign-btn:hover{
        color: #c00!important;
    }
}