.tags-list{
    display: flex;
    align-items: center;
    box-sizing: border-box;
    margin-bottom: 25px;
    .tags-item{
        height: 32px;
        margin-right: 12px;
        padding: 0 25px;
        box-sizing: border-box;
        background: #fff;
        border: 1px solid #dcdcdc;
        border-radius: 6px;
        cursor: pointer;
        font-size: 13px;
        font-weight: 500;
        line-height: 32px;
        text-align: center;
        transition: all 0.3s;
    }
    .tags-item.active{
        background: #fcf2f2;
        border: 1px solid #bc0000;
        border-radius: 6px;
        color: #bc0000;
        transition: all 0.3s;
    }
}