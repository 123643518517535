.ore-order{
    .ore-profit-title{
        color: #000;
        font-size: 16px;
    }
    .ore-profit{
        .amount{
            margin:15px 0 0;
            font-size: 36px;
            font-weight: 700;
        }
    }
}