.lang-switcher{
    position: relative;
    left: 0;
    top: 0;
    width: 50px;
    height: 48px;
    padding-right: 10px;
    cursor: pointer;
    transition: all .3s;
    .bitxialajiantou{
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(0,-6px);
        color: #000;
        font-weight: bold;
    }
    
}
.lang-box{
    display: flex;
    align-items: center;
    .lang-item{
        position: relative;
        left: 0;
        top: 0;
        width: 50px;
        height: 48px;
        cursor: pointer;
        transition: all .3s;
        
    }
}
.earth-icon{
    position: absolute;
    left:0px;
    top:0;
    display: inline-block;
    width: 48px;
    height: 48px;
    cursor: pointer;
    background: url('../../assets/sprit.png') no-repeat;
    transform: scale(.5);
    background-position-y: -260px;
    transition: all .3s;
}
.earth-icon-en{
    background-position-x: -40px;
    transition: all .3s;
}
.earth-icon-hk{
    background-position-x: -88px;
    transition: all .3s;
}
