.account-set-title{
    margin-bottom: 32px;
    font-size: 16px;
}
.account-com-title{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 34px;
    margin-bottom: 25px;
    background: #f7f7f7;
    border-radius: 5px;
    color: #000;
    font-size: 13px;
    font-weight: 600;
    .com-title{
        display: flex;
        align-items: center;
    }
    .com-title .personal,.com-title .enterprice{
        display: inline-block;
        width: 48px;
        height: 48px;
        background: url(../../assets/sprit1.png) no-repeat;
        background-position-y: -351px;
        transform: scale(.5);
    }
    .com-title .personal{
        background-position-x: -403px;
    }
    .com-title .enterprice{
        background-position-x: -453px;
    }
}
.account-com-box{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 120px;
    margin-bottom: 25px;
    padding: 0 30px;
    box-sizing: border-box;
    border: 1px solid #ececec;
    border-radius: 5px;
    .com-box-title{
        padding: 2px 0 11px;
        font-size: 15px;
        font-weight: 500;
        line-height: 1;
    }
    .com-box-desc{
        font-size: 14px;
        line-height: 16px;
        color: #999;
    }
    .com-box-btn{
        display: inline-block;
        height: 38px;
        min-width: 140px;
        padding: 0 20px;
        box-sizing: border-box;
        background: #f4f4f4;
        border-radius: 19px;
        border:1px solid #f4f4f4;
        font-size: 13px;
        color: #000;
        cursor: pointer;
        font-weight: 500;
        line-height: 38px;
        transition: all 0.2s;
    }
    .no-ban:hover{
        border:1px solid #c00;
        color: #c00;
        transition: all 0.2s
    }
    .com-box-btn.ban{
        color: #b3b3b3;
        cursor: no-drop;
    }
    .is-verify{
        color: #52c41a;
        font-size: 14px;
    }
}
.kyc-note{
    font-size: 13px;
    line-height: 22px;
}
