.free-item{
    overflow: hidden;
    width: 300px;
    height: 440px;
    /* background:linear-gradient(-70deg,#4c4a5b,#8f8d99); */
    border: 0.5px solid #dcdcdc;
    border-radius: 12px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,.04);
    transition: box-shadow .3s;
    color: #fff;
    text-align: center;
    .item-top{
        padding-bottom: 30px;
        background: linear-gradient(-70deg,#4c4a5b,#8f8d99);   
        text-align: center;
        .item-day{
            padding-top: 38px;
            margin-bottom: 15px;
            font-size: 22px;
            font-weight: 700;
            line-height: 21px;
            text-align: center;
        }
        .item-day.gray{
            color: #a0a0a0;
        }
        .item-pic{
            display: flex;
            justify-content: center;
            width: 90px;
            height: 90px;
            margin: 0 auto;
            .cover{
                height: inherit;
            }
        }
        .item-ratio{
            margin-top: 35px;
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
            .bittishi{
                position: relative;
                top: 2px;
                color: #888;
                font-size: 18px;
                cursor: pointer;
            }
        }
    }
    .item-bottoem{
        padding-bottom: 40px;
        background: linear-gradient(-70deg,#4c4a5b,#8f8d99);   
        .item-tag{
            position: relative;
            top: -9px;
            width: 80px;
            height: 18px;
            padding: 0 6px;
            margin:0 auto;
            background: linear-gradient(90deg,#f9e6c9,#e2c79d);
            border-radius: 4px;
            font-size: 12px;
            line-height: 18px;
            color: #000;
        }
        .free-num{
            margin: 30px 0 30px;
            font-size: 14px;
            font-weight: 400;
        }
        .item-btn{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 236px;
            height: 54px;
            margin: 0 auto ;
            padding-bottom: 1px;
            background: linear-gradient(90deg,#f9e6c9,#e2c79d);
            border-radius: 27px;
            cursor: pointer;
            color: #000;
            .out{
                color: #a1a1a1;
                font-size: 12px;
            }
            .buy{
                font-size: 16px;
                font-weight: 700;
            }
        }
        .item-btn:hover{
            background: linear-gradient(90deg,#e7c899,#daa755);
        }
    }
}