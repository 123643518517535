.no-data{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 50px;
    box-sizing: border-box;
    .img{
        width: 65px;
    }
    .note{
        color: #8f939e;
        font-size: 14px;
        line-height: 36px;
    }
}