.miner-hosting{
    padding: 0 50px;
    box-sizing: border-box;
    .hosting-box{
        display: flex;
        justify-content: center;
        align-items: center;
        .hosting-img{
            width: 600px;
            .img{
                width: 100%;
            }
        }
        .host-text{
            position: relative;
            width: 300px;
            height: 380px;
            margin-left: 150px;
            padding: 40px 30px;
            box-sizing: border-box;
            background: rgba(197,198,208,.2);
            text-align: left;
            .title{
                margin-bottom: 20px;
                font-size: 22px;
                font-weight: 700;
                line-height: 22px;
            }
            .desc{
                font-size: 14px;
                line-height: 28px;
            }
        }
    }
}