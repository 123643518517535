.miner{
    width: 100%;
    padding-bottom: 100px;
    box-sizing: border-box;
    background: #edeef4;
    .miner-step{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .miner-step-item{
            flex: 1;
            margin: 0 15px;
            .miner-step-title{
                display: flex;
                align-items: center;
                .miner-icon{
                    height: 32px;
                    width: 32px;
                    margin-right: 8px;
                }
                .title{
                    font-size: 14px;
                    font-weight: 500;
                }
            }
            .desc{
                margin-top: 5px;
                color: #787878;
                font-size: 14px;
                line-height: 24px;
            }
        }
    }
    .goods-list{
        display: flex;
        flex-wrap: wrap;
        margin-top: 20px;
        .goods-item{
            margin:0 30px 30px 0;
        }
        .goods-item:nth-child(4n){
            margin-right: 0;
        }
    }
}

