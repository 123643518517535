.g-detail-title{
    position: relative;
    padding: 50px 0 25px 25px;
    box-sizing: border-box;
    line-height: 42px;
    font-size: 28px;
    font-weight: 700;
}
.g-detail-title::after{
    position: absolute;
    left: 10px;
    top: 61px;
    content: '';
    width: 4px;
    height: 20px;
    background: #c00;
    border-radius: 1px;
}