.address-modal{
    padding: 34px 50px;
    box-sizing: border-box;
    border-radius: 12px;
    .address-content{
        .address-title{
            margin-bottom: 24px;
            font-size: 18px;
            font-weight: 700;
        }
        .address-ipt{
            margin-bottom: 20px;
        }
        .submit-address{
            display: flex;
            align-items: center;
            justify-content: center;
            .ls-btn{
                width: 280px;
            }
        }
    }
}