.partner{
    position: relative;
    overflow: hidden;
    height: 530px;
    padding-top: 138px;
    box-sizing: border-box;
    .partner-title{
        font-size: 34px;
        font-weight: 900;
        text-align: center;
    }
    .partner-list{
        margin: 0 auto;
        .partner-list-box{
            
            display: flex;
            justify-content: space-between;
            width: 545px;
            margin: 60px auto 0;
            .partner-item{
                width: 128px;
                .pimg{
                    width: inherit;
                }
            }
        }
    }
    .m-left{
        position: absolute;
        top: 0;
        left: 0;
        height: 510px;
        transform: translateX(-50%);
    }
    .m-right{
        position: absolute;
        top: 0;
        right: 0;
        height: 510px;
        transform: translateX(50%);
    }
}