.my-coupon-item{
    display: flex;
    position: relative;
    width: 395px;
    height: 203px;
    margin:10px 15px 0 0;
    padding: 35px 12px 0;
    box-sizing: border-box;
    background: url(../../assets/coupon-bg-1.png) no-repeat;
    background-size: 100%;
    line-height: 1;
    text-align: center;
    .left-con, .right-con{
        .title{
            color: #c8c8c8;
            font-size: 14px;
            margin-bottom: 22px;
        }
        .info{
            margin-bottom: 20px;
            color: #000;
            font-size: 12px;
        }
        
    }
    .left-con{
        width: 160px;
        .num{
            margin-bottom: 10px;
            color: #bc0000;
            font-family: Helvetica;
            font-size: 24px;
            font-weight: 700;
        }
    }
    .right-con{
        flex: 1;
        .rules{
            margin-bottom: 20px;
            color: #000;
            font-size: 14px;
            font-weight: 500;
        }
        .b-btn{
            height: 28px;
            padding: 0 20px;
            box-sizing: border-box;
            border-radius: 14px;
            background: #fff;
            border: 1px solid #e4e4e4;
            font-size: 13px;
            font-weight: 500;
            line-height: 26px;
            transition: all 0.2s;
            cursor: pointer;
        }
        .b-btn:hover{
            background-color: #c00;
            color: #fff;
            transition: all 0.2s;
        }
        
    }
    
    
}