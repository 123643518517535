.hashrate-list-title{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 30px 0 20px;
    .txt{
        font-size: 16px;
        font-weight: 700;
        line-height: 22px;
    }
    .list-right{
        display: flex;
        align-items: center;
        .pay-rules{
            display: flex;
            align-items: center;
            transition: all 0.3s;
            .txt{
                margin-left: 5px;
                text-decoration: underline;
                font-size: 14px;
                cursor: pointer;
                font-weight: 500;
            }
        }
        .pay-rules:hover{
            color: #c00;
            transition: all 0.3s;
        }
        .daily-btn{
            width: 130px;
            height: 32px;
            margin: 0 20px;
            padding: 0 15px;
            box-sizing: border-box;
            border: 1px solid #000;
            border-radius: 16px;
            color: #000;
            cursor: pointer;
            font-size: 14px;
            font-weight: 500;
            line-height: 32px;
            text-align: center;
        }
        .daily-btn:hover{
            border: 1px solid #c00;
            background-color: #c00;
            color: #fff;
        }
    }
}
.hashrate-list-tips{
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    height: 190px;
    background: #f7f7f9;
    padding: 0 30px;
    box-sizing: border-box;
    background: var(--bg-bg-4,#f7f7f9);
    border-radius: 5px;
    line-height: 18px;
    transform-origin: center;
    transition: all 0.3s;
    .close-btn{
        position: absolute;
        right: 20px;
        top: 20px;
        cursor: pointer;
    }
    .tips-title{
        margin-bottom: 5px;
        font-weight: 700;
        font-size: 16px;
    }
    .tips-info{
        margin-bottom: 10px;
        color: #878787;
        font-size: 14px;
    }
}
.hashrate-free-title{
    margin-bottom: 30px;
    font-size: 16px;
    .bitfont{
        margin-right: 8px;
        font-size: 24px;
        color: #ff9b22;
    }
}
.withdraw-profits{
    height: 24px;
    padding:  0 10px;
    box-sizing: border-box;
    background: linear-gradient(90deg,#f9e6c9,#e2c79d);
    border: none;
    border-radius: 12px;
    color: #000;
    font-size: 12px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
}