.my-coupon{
    .my-coupon-header{
        margin-bottom: 30px;
        font-size: 14px;
    }
    .tabs-list{
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        .tab-item{
            display: inline-block;
            padding: 0 20px;
            box-sizing: border-box;
            border-right: 1px solid #dcdcdc;
            cursor: pointer;
            font-size: 15px;
            font-weight: 550;
            color: #333;
            transition: all 0.3s;
        }
        .tab-item:last-child{
            border: none;
        }
        .tab-item.active{
            color: #c00;
            transition: all 0.3s;
        }
    }
    .tags-list{
        display: flex;
        align-items: center;
        margin-bottom: 30px;
        padding-left: 20px;
        box-sizing: border-box;
        .tags-item{
            height: 32px;
            margin-right: 12px;
            padding: 0 25px;
            box-sizing: border-box;
            background: #fff;
            border: 1px solid #dcdcdc;
            border-radius: 6px;
            cursor: pointer;
            font-size: 13px;
            font-weight: 500;
            line-height: 32px;
            text-align: center;
            transition: all 0.3s;
        }
        .tags-item.active{
            background: #fcf2f2;
            border: 1px solid #bc0000;
            border-radius: 6px;
            color: #bc0000;
            transition: all 0.3s;
        }
    }
    .my-coupon-list{
        display: flex;
        flex-wrap: wrap;
    }
}
