.ore-pay{
    display: flex;
    align-items: self-start;
    padding: 50px 0;
    box-sizing: border-box;
    .p-info-left{
        display: flex;
        flex-direction: column;
        flex: 1;
        .pay-info-title{
            display: flex;
            margin-bottom: 25px;
            font-size: 26px;
            font-weight: 700;
            line-height: 20px;
            .tag{
                display: flex;
                align-items: center;
                height: 24px;
                background: #f0f0f0;
                border-radius: 6px;
                margin-left: 16px;
                padding: 2px 9px 2px 2px;
                box-sizing: border-box;
                .img{
                    width: 20px;
                }
            }
        }
        .pay-info-output{
            margin: 25px 0 15px;
            font-size: 18px;
            font-weight: 400;
        }
        .pay-info-notes{
            position: relative;
            padding-left: 18px;
            margin-top: 15px;
            color: #787878;
            line-height: 24px;
            font-size: 14px;
            .notes-icon{
                position: absolute;
                left: 0;
                top: 12px;
                width: 6px;
                height: 6px;
                background-color: #c00;
                transform: rotate(45deg) translate(-2px,-2px);
            }
        }
        .coupon-list{
            width: 868px;
            padding:0 25px;
            box-sizing: border-box;
            background: #f7f7f7;
            border: 1px solid #dcdcdc;
            border-radius: 8px;
            .can-coupon-item{
                display: flex;
                flex-wrap: wrap;
                overflow-y: auto;
                max-height: 300px;
            }
        }
    }


    .p-info-right{
        position: sticky;
        top: 100px;
        width: 400px;
        margin-left: 30px;
        padding: 14px 15px 34px;
        box-sizing: border-box;
        background: #fff;
        border-radius: 10px;
        box-shadow: 0 3px 10px 0 rgba(0,0,0,.1);
        .p-info-right-total{
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 48px;
            .lab{
                font-size: 14px;
            }
            .price{
                color: #c00;
                font-size: 20px;
                font-weight: 550;
            }
        }
        .p-info-right-item{
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 48px;
            color: #999;
            .lab{
                font-size: 13px;
            }
            .price{
                font-size: 13px;
                color: #333;
            }
        }
        .accept-ruler{
            margin: 10px 15px 0;
            padding: 10px 0 24px;
            box-sizing: border-box;
            border-top: 0.5px solid #dcdcdc;
            font-size: 12px;
        }
        .sub-order-btn{
            height: 44px;
            max-width: 280px;
            margin: 0 auto;
            border-radius: 22px;
            font-size: 15px;
            font-weight: 600;
            line-height: 44px;
            text-align: center;
            color: #fff;
            cursor: pointer;
        }
    }
}
.ls-modal-box{
    padding: 34px 50px;
    box-sizing: border-box;
    .ls-modal-content{
        font-size: 14px;
        color: #333;
    }
    .ls-modal-footer{
        display: flex;
        justify-content: space-between;
        margin: 28px auto 0;
        text-align: center;
        .cancel{
            min-width: 140px;
            height: 38px;
            line-height: 38px;
            border-radius: 19px;
            font-size: 15px;
            background: #f7f7f7;
            border-color: #e4e4e4;
            color: #000;
            cursor: pointer;
            transition: all 0.3s;
        }
        .cancel:hover{
            background: #dcdcdc;
            transition: all 0.3s;
        }
        .verify{
            min-width: 140px;
            height: 38px;
            line-height: 38px;
            color: #fff;
            border-radius: 19px;
            font-size: 15px;
            cursor: pointer;
        }
    }
}