.point-body{
    .point-title{
        padding: 20px 0;
        margin-bottom: 30px;
        border-bottom: 0.5px solid #dcdcdc;
        font-size: 18px;
    }
    .point-info{
        display: flex;
        justify-content: space-between;
        .card-box{
            position: relative;
            width: 380px;
            margin-right: 50px;
            padding: 20px;
            box-sizing: border-box;
            background: linear-gradient(90deg,#3f4045,#1c1c20);
            border-radius: 8px;
            box-shadow: 0 12px 12px 0 rgba(0,0,0,.03);
            height: 166px;
            .card-Ava{
                color: #f4d6a8;
                font-size: 14px;
                line-height: 26px;
            }
            .card-point{
                color: #f4d6a8;
                font-size: 28px;
                font-weight: 700;
            }
            .card-no{
                margin-top: 10px;
                color: #fff;
                font-size: 14px;
                line-height: 26px;
            }
            .card-apply{
                margin-top: 5px;
                color: #fff;
                font-size: 14px;
                line-height: 16px;
                cursor: pointer;
            }
            .card-bg{
                position: absolute;
                right: 0px;
                top: 0px;
                width: 146px;
            }
            .card-btn{
                position: absolute;
                right: 20px;
                bottom: 20px;
                width: 90px;
                height: 34px;
                background: #fff;
                border-radius: 6px;
                color: #000;
                cursor: pointer;
                font-size: 14px;
                font-weight: 500;
                text-align: center;
                z-index: 1;
            }
        }
        .point-right{
            flex: 1;
            display: flex;
            flex-direction: column;
            .point-right-title{
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 8px;
                .txt{
                    font-size: 16px;
                }
            }
            .point-right-desc{
                line-height: 28px;
                font-size: 14px;
            }
            .purchase-btn{
                display: flex;
                align-items: center;
                justify-content: center;
                width: 280px;
                margin-top: 30px;
                background: #fff;
                border: 1px solid #e4e4e4;
                border-radius: 22px;
                font-size: 15px;
                font-weight: 600;
                height: 44px;
                line-height: 44px;
                transition: all 0.3s;
                .bitfont{
                    margin-right: 5px;
                }
            }
            .purchase-btn:hover{
                border: 1px solid #c00;
                transition: all 0.3s;
            }
        }
    }
}