.confrim-pay-modal{
    padding: 34px 50px;
    box-sizing: border-box;
    border-radius: 12px;
    .confrim-pay-content{
        .pay-tips{
            margin-bottom: 20px;
            font-size: 18px;
            font-weight: bold;
            text-align: center;
        }
        .confrim-pay-title{
            margin-bottom: 30px;
            font-size: 16px;
            text-align: center;
            .pay-amount{
                margin: 0 5px;
                color: #c00;
                font-size: 26px;
                font-weight: 700;
            }
        }
        .confrim-pay{
            display: flex;
            align-items: center;
            justify-content: center;
            .confrim-btn{
                width: 120px;
                margin: 0 10px;
            }
        }
    }
}