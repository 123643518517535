.product-detail{
    .p-d-title{
        margin: 50px 0 20px;
        color: rgba(0,0,0,.85);
        font-weight: 500;
        font-size: 16px;
    }
    .detail-table{
        width: 100%;
        border-right:1px solid #dcdcdc;
        border-bottom:1px solid #dcdcdc;
        .td{
            height: 60px;
            padding: 0 25px;
            border-left:1px solid #dcdcdc;
            border-top:1px solid #dcdcdc;
            .td-item{
                font-size: 14px;
            }
            .td-item:first-child{
                margin-bottom: 10px;
                color: #999
            }
        }
    }
}