.home-banner{
    width: 100%;
}
.bannerStyle{
    width: 100%;
    margin: 0 auto;
}
.ls-box{
    padding: 30px 0;
    box-sizing: border-box;
    background-color: #edeef4;
}
.ant-carousel .slick-dots li button{
    background-color: #999;
}
.ant-carousel .slick-dots li.slick-active button{
    background-color: #c00;
}