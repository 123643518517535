.navbar{
    position: sticky;
    top: 0px;
    z-index: 999;
    background-color: #fff;
    border-bottom: 1px solid #e1e1e1;
    .container{
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 80px;
        margin: 0 auto;
        .logo{
            height: 36px;
        }
        .navbar-center{
            display: flex;
            align-items: center;
            height: 100%;
            .nav-item{
                margin: 0 30px;
                .nav-link,.ant-dropdown-trigger{
                    position: relative;
                    font-size: 16px;
                    line-height: 16px;
                    font-weight: bold;
                    color: #333;
                    text-decoration: none;
                    cursor: pointer;
                }
                .nav-link:hover,.ant-dropdown-trigger:hover{
                    position: relative;
                    color: #c00;
                    transition: 0.3s;
                }
                .nav-link:hover::after,.ant-dropdown-trigger:hover::after{
                    content: '';
                    position: absolute;
                    bottom: -24px;
                    left: 50%;
                    width: 100%;
                    height: 3px;
                    margin-left: -50%;
                    animation: changeColor 0.5s forwards;
                    transition: 0.3s;
                }
                .active{
                    position: relative;
                    color: #c00;
                    transition: 0.3s;
                }
                .active::after{
                    content: '';
                    position: absolute;
                    bottom: -24px;
                    left: 50%;
                    width: 100%;
                    height: 3px;
                    margin-left: -50%;
                    animation: changeColor 0.5s forwards;
                    transition: 0.3s;
                }
                .gift-icon{
                    position: relative;
                    left: 5px;
                    bottom: 5px;
                    width: 20px;
                    animation:jumpGift 0.5s infinite;
                    transition: all 0.3s;
                }
            }
        }
        .user-group{
            display: flex;
            align-items: center;
            justify-content: center;
            /* margin-left: 100px; */
            .bitfont{
                cursor: pointer;
            }
            .bityonghutouxiang{
                font-size: 30px;
            }
            .bitAPPku{
                font-size: 24px;
            }
            .btn-dash{
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0 20px;
                background-color: #fff;
                border: 1.5px solid #000;
                border-radius: 24px;
                height: 48px;
                min-width: 120px;
                cursor: pointer;
                color: #000;
                font-size: 15px;
                font-weight: 550;
            }
            .btn-dash:hover{
                border: 1.5px solid #c00;
                color:#c00;
            }
        }
    }
    
}

.init-bg{
    background-color: #333!important;
}
/* .ant-dropdown-menu,.ant-dropdown .ant-dropdown-arrow:before{
    background-color: #333!important;
} */

@keyframes changeColor {
    0%{
        background-color: transparent;
    }
    100%{
        background-color: #c00;
    }
}

@-webkit-keyframes changeColor {
    0%{
        background-color: transparent;
    }
    100%{
        background-color: #c00;
    }
}

@keyframes jumpGift{
    from{
        bottom: 0px;
    }
    to{
        bottom: 3px;
    }
}
@-webkit-keyframes jumpGift{
    from{
        bottom: 0px;
    }
    to{
        bottom: 3px;
    }
}