.free-info{
    display: flex;
    justify-content: space-between;
    padding: 50px 0;
    box-sizing: border-box;
    .info-left{
        .info-cover{
            display: flex;
            justify-content: center;
            align-items: center;
            height: 320px;
            width: 489px;
            background: linear-gradient(30deg,rgba(234,237,244,.5),rgba(248,248,250,.5));
            border-radius: 8px;
            .cover{
                width: 306px;
            }
        }
        .info-desc{
            margin-top: 20px;
            list-style: square;
            padding-left: 20px;
            .desc-item{
                color: #787878;
                line-height: 30px;
                font-size: 14px;
            }
        }
    }
    .info-right{
        flex: 1;
        margin-left: 50px;
        .right-title{
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            font-size: 28px;
            font-weight: bold;
            .tag{
                display: flex;
                align-items: center;
                height: 24px;
                margin-left: 16px;
                padding: 2px 9px 2px 2px;
                background: #f0f0f0;
                border-radius: 6px;
                font-size: 12px;
                font-weight: normal;
                .img{
                    width: 20px;
                    margin-right: 8px;
                }
            }
        }
        .info-amount{
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            padding:10px 20px;
            box-sizing: border-box;
            background: #f7f7f7;
            border: 0.5px solid #dcdcdc;
            border-radius: 8px;
            font-size: 12px;
            .new-power{
                font-size: 20px;
                color: #c00;
                margin-right: 10px;
            }
        }
        .info-data{
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 178px;
            padding:0 20px;
            border: 0.5px solid #dcdcdc;
            border-radius: 8px;
            .info-item{
                display: flex;
                align-items: center;
                height: 40px;
                .info-lab{
                    color: #787878;
                    font-size: 14px;
                    font-weight: 400;
                }
                .info-val{
                    font-size: 14px;
                    .bitfont{
                        color: #ccc;
                    }
                }
                .info-val.red-color{
                    color: #c00;
                }
            }
        }
        .buy-now-btn{
            display: flex;
            justify-content: center;
            align-items: center;
            height: 44px;
            width: 280px;
            margin-top: 15px;
            background: #c00;
            border-radius: 23px;
            color: #fff;
            cursor: pointer;
            font-size: 15px;
            font-weight: 600;
        }
        .buy-now-btn.gray{
            background: #787878;
        }
    }
}