.cloud-list{
    width: 100%;
    padding:50px 0 100px;
    background: #edeef4;
    border-top: 1px solid rgba(0,0,0,.1);
    .cloud-list-tips{
        color: #787878;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
    }
    .goods-list{
        display: flex;
        flex-wrap: wrap;
        margin-top: 30px;
        .goods-item{
            margin:0 30px 30px 0;
        }
        .goods-item:nth-child(4n){
            margin-right: 0;
        }
    }
}