.account-set-title{
    margin-bottom: 32px;
    font-size: 16px;
}
.account-info{
    display: flex;
    height: 130px;
    margin-bottom: 34px;
    padding: 35px 30px;
    box-sizing: border-box;
    border: 1px solid #dcdcdc;
    border-radius: 5px;
    .account-info-item:last-child{
        padding: 0 0 0 30px;
        box-sizing: border-box;
        border-left: 1px solid #ddd;
    }
    .account-name{
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        cursor: pointer;
        font-size: 18px;
        font-weight: 500;
        .bitfont{
            font-size: 14px;
            font-weight: bold;
        }
    }
    .withdraw-verify{
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        cursor: pointer;
        font-size: 16px;
        font-weight: 500;
        .bitfont{
            font-size: 14px;
        }
    }
    .account-name:hover,.withdraw-verify:hover{
        color: #c00;
    }
    .account-uid{
        font-size: 14px;
        .bitfont{
            margin-left: 8px;
            font-size: 18px;
            cursor: pointer;
        }
        .bitfont:hover{
            color: #c00;
        }
    }
    .withdraw-verify-icon .bitfont{
        margin-right: 10px;
        font-size: 24px;
        color: #dcdcdc;
        cursor: pointer;
    }
    .withdraw-verify-icon .bitfont.active{
        color: #c00;
    }
}

.account-com-content{
    overflow: hidden;
    height: 140px;
    transition: all 0.3s;
    .account-com-title{
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 34px;
        margin-bottom: 12px;
        padding:0 20px;
        box-sizing: border-box;
        background: #f7f7f7;
        border-radius: 5px;
        color: #000;
        font-size: 13px;
        font-weight: 600;
        .bitfont{
            transform: rotate(-180deg);
            transition: all 0.3s;
            cursor: pointer;
        }
        .bitfont.rotate{
            transform: rotate(0deg);
            transition: all 0.3s;
        }
    }
    .account-com-box{
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 94px;
        padding: 0 25px;
        box-sizing: border-box;
        border: 1px solid #dcdcdc;
        .icon-box{
            width: 44px;
            height: 44px;
            margin-right: 20px;
            padding: 8px;
            box-sizing: border-box;
            background-color: #e4e4e4;
            border-radius: 50%;
            .bitfont{
                color: #444;
                font-size: 28px;
            }
        }
        .com-box-title{
            padding: 2px 0 11px;
            font-size: 16px;
            font-weight: 500;
            line-height: 1;
        }
        .com-box-desc{
            color: #b4b4b4;
            font-size: 14px;
            line-height: 16px;
        }
        .com-box-btn{
            display: inline-block;
            height: 32px;
            min-width: 110px;
            border-radius: 16px;
            background: #fff;
            border: 1px solid #e4e4e4;
            font-size: 13px;
            line-height: 30px;
            color: #000;
            transition: all 0.2s
        }
        .com-box-btn:hover{
            border-color: #c00;
            color: #c00;
            transition: all 0.2s
        }
    }
    
}
.account-com-content.hidebox{
    overflow: hidden;
    height: 36px;
    transition: all 0.3s;
}
