.about-box-body{
    padding:100px 30px;
    box-sizing: border-box;
    .title{
        margin-bottom: 50px;
        text-align: center;
    }
    .about-desc{
        margin-bottom: 25px;
        font-size: 16px;
        text-indent: 36px;
        line-height: 36px;
    }
}