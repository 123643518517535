.invitee-body{
    padding-bottom: 60px;
    box-sizing: border-box;
    background-color: #edeef4;
    .invitee-banner{
        /* background: #fac0c8; */
        background: linear-gradient(180deg,#fac0c8,rgba(250,192,200,0)) no-repeat;
        .invitee-banner-content{
            position: relative;
            height: 465px;
            .b-img-box{
                width: inherit;
                height: inherit;
                text-align: right;
                .b-img{
                    height: inherit;
                }
            }
            .invitee-banner-txt{
                position: absolute;
                padding: 60px 0;
                box-sizing: border-box;
                .invitee-banner-title{
                    font-size: 25px;
                    font-weight: 500;
                    .icon{
                        width: 25px;
                        margin-right: 15px;
                    }
                }
                .invitee-banner-username{
                    margin-top: 80px;
                    .info1{
                        font-size: 24px;
                    }
                    .info2{
                        font-size: 48px;
                        font-weight: 800;
                    }
                }
                .invitee-free-btn{
                    height: 40px;
                    margin-top: 50px;
                    padding: 0 30px;
                    font-size: 20px;
                    font-weight: 700;
                }
                .invitee-num{
                    display: flex;
                    align-items: center;
                    margin-top: 20px;
                    color: #000;
                    font-size: 16px;
                    .ant-statistic-content{
                        color: #c00;
                        margin-right: 3px;
                        font-size: 16px;
                    }
                }



                
                
            }
        }
        
    }
    .invitee-bg-box{
        background: #fff!important;
        .invitee-step-title{
            padding: 30px 0;
            box-sizing: border-box;
            font-size: 30px;
            font-weight: 700;
            text-align: center;
        }
        .invitee-steps{
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            z-index: 2;
            top: -1px;
            padding:0 60px 30px;
            box-sizing: border-box;
            .invitee-steps-item{
                flex: 1;
                display: flex;
                align-items: center;
                .step-icon{
                    width: 68px;
                    margin-right: 20px;
                }
                .step-title{
                    color: #000;
                    font-size: 14px;
                    font-weight: 700;
                    margin-bottom: 7px;
                }
                .step-desc{
                    color: #000;
                    font-size: 14px;
                }
            }
        }
        .invitee-ask{
            display: flex;
            align-items: center;
            justify-content: center;
            padding-bottom: 60px;
            .invitee-ask-item{
                display: flex;
                flex-direction: column;
                align-items: center;
                flex: 1;
                .invitee-ask-item-img{
                    width: 360px;
                }
                .invitee-ask-item-desc{
                    margin-top: 25px;
                    font-size: 14px;
                    font-weight: 700;
                    text-align: center;
                }
            }
        }
    }
}
