.coupon-box-body{
    padding-bottom: 30px;
    box-sizing: border-box;
    .banner{
        width: 100%;
        margin: 30px 0;
    }
    .coupon-box{
        display: flex;
        flex-direction: column;
        .coupon-box-item{
            display: flex;
            flex-direction: column;
            margin-bottom: 25px;
            padding: 50px 40px 0px;
            box-sizing: border-box;
            background: #fff;
            border-radius: 4px;
            .coupon-title{
                display: flex;
                align-items: center;
                font-size: 24px;
                font-weight: 500;
                .left-icon{
                    display: inline-block;
                    height: 56px;
                    width: 56px;
                    background: url(../../assets/sprit1.png) no-repeat;
                    background-position: -25px -190px;
                    transform: scale(.4);
                }
                .coupon-desc{
                    margin-left: 10px;
                    font-size: 14px;
                    font-weight: 500;
                    color: #666;
                }
            }
        }
        .coupon-claim-item{
            display: flex;
            flex-wrap: wrap;
            .no-coupons-box{
                display: flex;
                justify-content: center;
            }
        }
        
    }
}