.static-output{
    .s-o-title{
        color: rgba(0,0,0,.85);
        font-weight: 500;
        font-size: 16px;
    }
    .s-o-analysis{
        margin-top: 18px;
        padding: 24px 24px 16px;
        box-sizing: border-box;
        background: #f7f7f7;
        border: 0.5px solid #dcdcdc;
        border-radius: 8px;
        .analysis-box{
            height: 300px;
            background: #fff;
            border: 0.5px solid #dcdcdc;
            border-radius: 8px;
        }
    }
    .s-o-note{
        .note{
            color: #000;
        }
        margin-top: 20px;
        color: #787878;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
    }
}