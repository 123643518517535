.disclaimer-content{
    margin-top: 28px;
    padding: 35px 30px;
    box-sizing: border-box;
    background: #f7f7f7;
    border: 1px solid #dcdcdc;
    border-radius: 8px;
    .disclaimer-item{
        margin-top: 15px;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
    }
}