.dashboard-body{
    display: flex;
    justify-content: space-between;
    border-radius: 12px;
    background-color:#fafafa;
    .dashboard-menu{
        display: flex;
        flex-direction: column;
        width: 205px;
        height: 760px;
        padding:15px 0 30px;
        box-sizing: border-box;
        .d-menu-item{
            padding-left: 10px;
            box-sizing: border-box;
            line-height: 60px;
            font-size: 16px;
            font-weight: 500;
            .item-icon{
                display: inline-block;
                position: relative;
                top: 15px;
                height: 44px;
                width: 44px;
                transform: scale(.4);
                background: url('../../assets/sprit1.png') no-repeat;
            }
            .item-icon.fortune{
                background-position: -240px -44px;
                
            }
            .item-icon.service{
                background-position: -324px -44px;
            }
            .item-icon.order{
                background-position: -408px -44px;
            }
            .item-icon.invite{
                background-position: -492px -44px;
            }
            .item-icon.coupon1{
                background-position: -578px -44px;
            }
            .item-icon.coupon2{
                background-position: -854px -44px;
            }
            .item-icon.setting{
                background-position: -659px -44px;
            }
           
        } 
        .d-menu-item-child{
            position: relative;
            padding-left: 54px;
            font-size: 14px;
            line-height: 40px;
        }
        .d-menu-item-child .dot-box{
            position: relative;
        }
        .d-menu-item-child .dot-box .dot{
            position: absolute;
            right: -9px;
            top: 0px;
            width: 6px;
            height: 6px;
            background: #c00;
            border-radius: 50%;
        }
        .active{
            position: relative;
            color: #c00!important;
        }
        .active::before{
            content: "";
            position: absolute;
            top:50%;
            left: 0;
            width: 6px;
            height: 22px;
            margin-top: -12px;
            background: #bc0000;
            border-radius: 0 3px 3px 0;
        }
    }
    .dashboar-content{
        overflow-y: auto;
        flex: 1;
        min-height: 700px;
        padding: 30px 50px;
        box-sizing: border-box;
        background-color: #fff;
    }
}