.ore-item{
    overflow: hidden;
    width: 300px;
    height: 440px;
    background: #fff;
    border: 0.5px solid #dcdcdc;
    border-radius: 12px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,.04);
    transition: box-shadow .3s;
    text-align: center;
    .item-top{
        padding-bottom: 20px;
        background: linear-gradient(30deg,rgba(234,237,244,.5),hsla(0,0%,100%,.5));   
        text-align: center;
        .item-day{
            padding-top: 38px;
            margin-bottom: 15px;
            font-size: 22px;
            font-weight: 700;
            line-height: 21px;
            text-align: center;
        }
        .item-day.gray{
            color: #a0a0a0;
        }
        .item-pic{
            display: flex;
            justify-content: center;
            width: 150px;
            height: 150px;
            margin: 0 auto;
            .cover{
                height: inherit;
            }
        }
        .item-name{
            height: 21px;
            margin-top: 15px;
            color: #000;
            font-size: 16px;
            font-weight: 700;
            line-height: 21px;
        }
    }
    .item-bottome{
        background-color: #fff;
        .item-h-fee{
            margin-top: 20px;
            height: 21px;
            font-size: 14px;
            font-weight: 400;
            .num{
                margin-left: 5px;
                color: #c00;
                font-size: 18px;
                font-weight: 700;
                line-height: 21px;
            }
            .uint{
                font-size: 14px;
                font-weight: 400;
            }
        }
        .item-btn{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 236px;
            height: 54px;
            margin: 30px auto 0;
            padding-bottom: 1px;
            border-radius: 27px;
            border: 1px solid #e1e1e1;
            cursor: pointer;
            .out{
                color: #a1a1a1;
                font-size: 12px;
            }
            .buy{
                font-size: 16px;
                font-weight: 700;
            }
        }
        .item-btn:hover{
            background-color: #c00;
            color: #fff;
            .out{
                color: #fff;
            }
        }
    }
}