.withdraw-box{
    width: 450px;
    margin-top: 30px;
    padding: 25px 30px;
    box-sizing: border-box;
    border-radius: 8px;
    border: 1px solid #ececec;
    .select-address{
        display: flex;
        align-content: center;
        .add-btn{
            margin-left: 10px;
            font-size: 24px;
            color: #c00;
            cursor: pointer;
        }
    }
    .ipt-amount{
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        color: #666;
    }
    .withdraw-tips{
        margin-top: 15px;
        font-size: 14px;
        color: #666;
    }
    .amount{
        color: #c00;
    }
    .big-amount{
        font-size: 28px;
        font-weight: 500;
        color: #c00;
    }
}