.my-fortune{
    .fortune-note{
        margin-bottom: 20px;
        font-size: 14px;
        font-weight: 500;
    }
    .fortune-kyc-verify{
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 94px;
        padding: 0 30px 0 25px;
        margin-bottom: 12px;
        box-sizing: border-box;
        background: linear-gradient(90deg,#f0f2f7,#fff);
        border: 1px solid #dcdcdc;
        border-radius: 8px;
        .icon{
            width: 44px;
            height: 44px;
            border-radius: 22px;
            line-height: 44px;
            text-align: center;
            background-color: #fff;
            .bitfont{
                font-size: 26px;
            }
        }
        .mid-part{
            flex: 1;
            margin: 0 20px;
            .title{
                margin-bottom: 10px;
                color: #000;
                font-size: 16px;
                font-weight: 500;
                line-height: 1;
            }
            .desc{
                color: #b4b4b4;
                font-size: 14px;
                line-height: 16px;
            }
        }
        .fortune-btn{
            min-width: 110px;
            height: 32px;
            line-height: 30px;
            border-radius: 16px;
            border: 1px solid #e4e4e4;
            background-color: #fff;
            cursor: pointer;
            transition: all 0.3s;
        }
        .fortune-btn:hover{
            border: 1px solid #c00;
            color: #c00;
            transition: all 0.3s;
        }
        .is-verify{
            color: #52c41a;
        }
    }
    .module-title{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 35px 0 25px;
        color: #000;
        font-size: 16px;
        font-weight: 500;
        .fortune-btn-group{
            .detail-btn,.buy-btn{
                display: inline-block;
                height: 32px;
                padding: 0 20px;
                box-sizing: border-box;
                min-width: 110px;
                border-radius: 16px;
                line-height: 32px;
                cursor: pointer;
                font-size: 15px;
                font-weight: 700;
                text-align: center;
                transition: all 0.3s;
                margin-left: 20px;
            }
            .detail-btn{
                border: 1.5px solid #000;
            }
            .buy-btn{
                border: 1.5px solid #000;
                color: #fff;
                background-color: #000;
            }
            .h-btn:hover{
                border: 1.5px solid #c00;
                background-color: #c00;
                color: #fff;
                transition: all 0.3s;
            }
        }
    }
    .total-assets,.hashrate-hosting{
        padding-bottom: 20px;
        border-bottom: 1px solid #dcdcdc;
        .amount{
            margin-bottom: 10px;
            font-size: 36px;
            font-weight: 700;
        }
        .btc-amount{
            color: #a8a8a8;
            font-size: 14px;
        }
    }
    .hashrate-hosting{
        border: none;
        .amount{
            font-size: 26px;
        }
    }
    .owner-box{
        display: flex;
        align-items: center;
        padding-left: 30px;
        height: 144px;
        width: 300px;
        border-radius: 5px;
        border: 1px solid #ececec;
        .owner-left{
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-right: 30px;
            .coinname{
                margin-top: 10px;
                font-size: 12px;
                font-weight: 700;
                text-align: center;
            }
        }
        .owner-right{
            display: flex;
            flex-direction: column;
            justify-content: center;
            .name{
                font-size: 15px;
                line-height: 24px;
            }
            .num{
                margin: 5px 0;
                font-size: 20px;
                font-weight: 500;
            }
            .output{
                color: #a8a8a8;
                font-size: 14px;
            }
        }
    }
}