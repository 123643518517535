.wallet-box{
    position: relative;
    width: 380px;
    padding: 20px;
    box-sizing: border-box;
    background: linear-gradient(90deg,#3f4045,#1c1c20);
    border-radius: 8px;
    box-shadow: 0 12px 12px 0 rgba(0,0,0,.03);
    height: 166px;
    .wallet-point{
        display: flex;
        align-items: center;
        color: #f4d6a8;
        font-size: 28px;
        font-weight: 700;
        .unit{
            margin-left: 10px;
            font-weight: normal;
            font-size: 20px;
            color: #999;
        }
    }
    .wallet-no{
        color: #999;
        font-size: 14px;
        line-height: 26px;
    }
    .wallet-bg{
        position: absolute;
        right: 0px;
        top: 0px;
        width: 146px;
    }
    .btn-group{
        display: flex;
        align-items: center;
        border-top: 1px dashed #666;
        margin-top: 20px;
        padding-top: 10px;
        box-sizing: border-box;
        .wallet-btn{
            margin-right:10px;
            background-color: transparent;
            color: #f4d6a8;
            border: 1px solid #666;
        }
        .wallet-btn:hover{
            color: #fff;
        }
    }
    
}