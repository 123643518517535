.register{
    .sign-btn{
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 20px;
        background-color: #fff;
        border: 1.5px solid #000;
        border-radius: 24px;
        height: 48px;
        min-width: 120px;
        cursor: pointer;
        color: #000;
        font-size: 15px;
        font-weight: 550;
    }
    .sign-btn:hover{
        border: 1.5px solid #c00;
        color:#c00;
    }
}