.tab-miner{
    padding-top: 30px;
    box-sizing: border-box;
    .miner-box{
        display: flex;
        justify-content: center;
        padding: 0 100px;
        .img-box{
            width: 640px;
            .img{
                width: inherit;
            }
        }
        .tab-miner-info{
            width: 300px;
            height: 380px;
            margin-left: 150px;
            padding: 40px 30px;
            box-sizing: border-box;
            background: rgba(197,198,208,.2);
            text-align: left;
            .title{
                margin-bottom: 20px;
                font-size: 22px;
                font-weight: bold;
            }
            .desc{
                font-size: 15px;
                line-height: 24px;
                max-width: 300px;
            }
            // .item{
            //     position: relative;
            //     margin: 15px 0;
            //     .title{
            //         margin-bottom: 20px;
            //         font-size: 22px;
            //         font-weight: bold;
            //     }
            //     .desc{
            //         font-size: 15px;
            //         line-height: 24px;
            //         max-width: 400px;
            //     }
            // }
            .item::after{
                background: url(../../assets/sprit.png) no-repeat;
                content: "";
                display: inline-block;
                height: 68px;
                left: -70px;
                position: absolute;
                top: -10px;
                transform: scale(.5);
                width: 68px;
            }
            .item1::after{
                background-position: -40px -748px;
            }
            .item2::after{
                background-position: -132px -748px;
            }
            .item3::after{
                background-position: -224px -748px;
            }
        }
    }

}

