.need-help{
    margin: 100px auto 0;
    padding : 60px;
    box-sizing: border-box;
    background: linear-gradient(45deg,#e1e2e9,#e9e9f0);
    border-radius: 12px;
    .n-h-title{
        display: flex;
        justify-content: center;
        font-size: 34px;
        font-weight: 900;
        text-align: center;
        .txt{
            position: relative;
            .wenhao{
                position: absolute;
                right: -30px;
                top: -30px;
                height: 60px;
                transform: scale(.5);
                width: 32px;
                background: url(../../../assets/sprit.png) no-repeat;
                background-position: -438px -472px;
            }
        }
        
    }
    .need-help-list{
        display: flex;
        justify-content: space-between;
        margin-top: 40px;
        .help-item{
            position: relative;
            width: 360px;
            min-height: 240px;
            padding: 38px 0;
            background: #fff;
            border-radius: 8px;
            cursor: pointer;
            text-align: center;
            transition: all .3s;
            .help-item-img{
                height: 68px;
                width: 68px;
            }
            .help-item-title{
                margin-bottom: 20px;
                margin-top: 18px;
                font-size: 20px;
                font-weight: 700;
                line-height: 20px;
                
            }
            .help-item-desc{
                font-size: 16px;
                padding: 0 65px;
            }
        }
    }
}