.world-leader{
    padding-top: 36px;
    margin: 100px auto 0;
    background: #fff;
    border-radius: 12px;
    .title1{
        color: #eeb374;
        font-size: 18px;
        font-weight: 400;
        text-align: center;
    }
    .top-title{
        margin-top: 18px;
        font-size: 34px;
        font-weight: 700;
        text-align: center;
    }
    .top-desc{
        padding:0 25px;
        box-sizing: border-box;
        margin: 20px auto 0;
        color: #787878;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        text-align: center;
        word-spacing: 0;
    }
    .leader{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 40px;
        padding: 25px;
        box-sizing: border-box;
        text-align: center;
        .leader-item{
            display: flex;
            align-items: center;
            width: 48%;
            height: 150px;
            margin-right: 2%;
            margin-bottom: 40px;
            padding-left: 26px;
            box-sizing: border-box;
            background: #fff;
            border: 1px solid #edeef4;
            border-radius: 10px;
            .left-icon{
                height: 68px;
                width: 68px;
                margin-right: 16px;
                .img{
                    width: inherit;
                }
            }
            .right-text{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                .title{
                    margin-bottom: 15px;
                    font-size: 20px;
                    font-weight: 700;
                }
                .desc{
                    margin-bottom: 8px;
                    font-size: 15px;
                    font-weight: 400;
                }
            } 
        }
        .leader-item:nth-child(2n){
            margin-right: 0;
        }
    }
}