.tab-header{
    padding: 50px 0;
    background: linear-gradient(45deg,#e1e2e9,#e9e9f0);
    .tab-h-content{
        display: flex;
        justify-content: space-between;
        margin: 0 auto;
        .show-all{
            overflow: hidden;
            height: 274px;
            transition: all .25s ease-out;
            .show-all-box{
                display: inline-flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 110px;
                height: 104px;
                background: #edeef4;
                border-radius: 12px;
                cursor: pointer;
                transition: border .2s;
                border: 1px solid #edeef4;
                .icon{
                    transition: 0.3s ease-out;
                }
            }
            .show-all-box:hover{
                border: 1px solid #333;
            }
            .show-info-lab{
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                .info-lab{
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    width: 80px;
                    height: 34px;
                    color: #787878;
                    line-height: 1;
                    text-align: right;
                    font-size: 12px;
                }
            }
        }
        .show-all.allinfo{
            height: 106px;
            transition: all .25s ease-out;
            .icon{
                transform: rotate(180deg);
                transition: 0.3s ease-out;
            }
        }
        
    }
    .tab-item{
        overflow: hidden;
        flex: 1;
        height: 274px;
        background: #edeef4;
        border: 1.5px solid transparent;
        cursor: pointer;
        margin-left: 16px;
        border-radius: 15px;
        transition: all .25s ease-out;
        .tab-item-header{
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 105px;
            padding:0 25px;
            box-sizing: border-box;
            transition: all .25s ease-out;
            .i-header{
                font-size: 18px;
                font-weight: 700;
                .icon{
                    height: 30px;
                    width: 30px;
                    margin-right: 10px;
                }
            }
            .i-h-desc{
                margin-top: 20px;
                color: #787878;
                font-size: 14px;
                white-space: nowrap;
            }
        }
        .tab-item-info{
            .info-item{
                height: 34px;
                padding-left: 24px;
                font-size: 13px;
                font-weight: 400;
                line-height: 34px;
            }
            .info-item:nth-child(2n){
                background: #fff;
            }
            .info-item:nth-child(2n+1){
                background: #f5f5fa;
            }
        }
    }
    .tab-item:hover{
        box-shadow: 0 0 10px #999;
        transition: all .25s ease-out;
    }
    .tab-item.active{
        border: 1.5px solid #999;
        .tab-item-header{
            background-color: #f9f9f9;
            transition: all .25s ease-out;
        }
    }
    .tab-item.hideInfo{
        height: 105px;
        transition: all .25s ease-out;
    }
}