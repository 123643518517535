.qr-modal-box{
    .qr-img-con{
        padding:40px 40px 20px;
        box-sizing: border-box;
        background: linear-gradient(30deg,#eaedf4,#fff);
        position: relative;
        .qr-bg{
            width: 420px;
        }
        .qr-img-footer{
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 140px;
            padding-left: 20px;
            box-sizing: border-box;
            background-color: #fff;
            .qrcode-txt{
                font-size: 20px;
                font-weight: 500;
                color: #c00;
            }
        }
        
    }
    .qr-modal-footer{
        padding:20px 25px 0;
        box-sizing: border-box;
        background-color: #fff;
        .act-item{
            display: flex;
            align-items: center;
            justify-content: space-between;
            border: 1px solid #e4e4e4;
            border-radius: 8px;
            height: 48px;
            margin-bottom: 15px;
            padding: 0 20px;
            box-sizing: border-box;
            font-size: 12px;
            line-height: 18px;
            .act-item-lab{
                display: inline-block;
            }
            .act-item-txt{
                flex: 1;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                text-align: right;
                .bitfont{
                    margin-left: 5px;
                    font-size: 20px;
                    cursor: pointer;
                }
                .bitfont:hover{
                    color: #c00;
                }
            }
        }
        .act-btn-group{
            display: flex;
            justify-content: center;
            .bitfont{
                margin: 0 10px;
                font-size: 32px;
                cursor: pointer;
            }
        }
    }
}