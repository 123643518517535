.daily-earning-title{
    display: flex;
    align-items: center;
    margin: 30px 0;
    .d-e-title{
        color: #000;
        font-size: 20px;
        font-weight: 500;
    }
    .select-currency{
        display: flex;
        align-items: center;
        margin-left: 60px;
        font-size: 14px;
        color: #666;
    }
}