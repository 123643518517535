body,ul,ol,li,p,h1,h2,h3,h4,h5,h6,form,fieldset,table,td,img,div{margin:0;padding:0;border:0;}
ul,ol{list-style:none;}
select,input,img,select{vertical-align:middle;}
a{text-decoration:none;color: #333;}
/* a:link{color:#000;} */
/* a:visited{color:#000;} */
.d-menu-item a,.d-menu-item-child a{color: #000;}
.d-menu-item.active a,.d-menu-item-child.active a{color: #c00;font-weight: 500;}
a:hover,a:active,a:focus{color:#c00;}
i{font-style: normal;}
.h-w1300{max-width: 1440px;margin: 0 auto;padding: 0 25px;box-sizing: border-box;}
.body-content{ min-height: calc(100vh - 362px)!important;}
.ant-input-number-input{text-align: center!important;}
.ant-modal-content{ padding: 0!important;}
.ant-table-filter-trigger.active{color: #c00!important}

.ls-btn{ background: linear-gradient(90deg,#eb0017,#c00);}
.ls-btn:hover{background: linear-gradient(90deg,#b91122,#b51121);}
.common-page-header{display: flex;align-items: center;justify-content: space-between;margin-bottom: 30px;}
.common-page-header .title{ font-size: 20px; font-weight: 700; height: 17px;line-height: 17px;}
.common-page-header .first-btn,.common-page-header .last-btn{display: inline-block;min-width: 110px; height: 32px;border-radius: 16px; border: 1px solid; font-size: 13px; line-height: 30px;cursor: pointer;}
.common-page-header .first-btn{background-color: #fff;}
.common-page-header .last-btn{background-color: #c00;color: #fff;margin-left: 20px;}
.common-page-header .first-btn:hover{border-color: #c00;color: #c00;}
.common-page-header .last-btn:hover{background-color: rgb(174, 5, 5);}
.rankimg{width: 20px;text-align: center;}
.deposit-title{ display: flex; align-items: center;margin-top: 30px; font-size: 18px;font-weight: 500;}
.deposit-title .bitqianbao{margin-right: 10px; font-size: 28px;}

/* .ls-flex{ display: flex;}
.ls-flex-ac{ display: flex;justify-content: center;}
.ls-flex-ajc{ display: flex; justify-content: center; align-items: center;}
.ls-flex-ajsb{ display: flex; align-items: center;justify-content: space-between;} */