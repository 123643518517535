.ore{
    width: 100%;
    padding:50px 0 100px;
    box-sizing: border-box;
    background: #edeef4;
    .miner-title{
        margin-bottom: 15px;
        .tag1{
            height: 18px;
            padding: 0 5px;
            margin-right: 10px;
            border-radius: 4px;
            background: hsla(37,61%,70%,.3);
            font-family: Lato-Bold;
            font-size: 12px;
            font-weight: 700;
            line-height: 18px;
        }
        .tag2{
            height: 18px;
            padding: 0 5px;
            margin-right: 10px;
            border-radius: 4px;
            background: rgba(100,92,161,.3);
            font-family: Lato-Bold;
            font-size: 12px;
            font-weight: 700;
            line-height: 18px;
        }
        .miner-tips{
            color: #787878;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
        }
    }
    
    .goods-list{
        display: flex;
        flex-wrap: wrap;
        margin-top: 30px;
        .goods-item{
            margin:0 30px 30px 0;
        }
        .goods-item:nth-child(4n){
            margin-right: 0;
        }
    }
}