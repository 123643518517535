.coupon-item{
    width: 361px;
    height: 200px;
    padding-top: 30px;
    background: url(../../assets/task_coupon_bg.png) no-repeat;
    background-size: 100%;
    text-align: center;
    .title{
        margin-bottom: 20px;
        color: #000;
        font-size: 16px;
        font-weight: 700;
    }
    .price{
        font-size: 36px;
        font-weight: 700;
        line-height: 26px;
        color: #c00;
        .fuhao{
            font-size: 22px;
            font-weight: normal;
        }
    }
    .desc{
        margin: 15px 0 15px;
        color: #a0a0a0;
        font-size: 12px;
    }
    .claim-btn{
        min-width: 120px;
        height: 32px;
        border-radius: 16px;
        border: none;
        font-size: 14px;
        font-weight: 600;
        color: #fff;
    }
    .claim-btn.ban{
        color: rgba(0,0,0,.25);
        cursor: no-drop;
        background-color: #f5f5f5;
    }
}