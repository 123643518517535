.invite-body{
    background-color: #edeef4;
    .invite-banner{
        background: #fac0c8;
        .invite-banner-content{
            position: relative;
            padding: 0;
            .b-img{
                width: 100%;
                margin-bottom: -6px;
            }
            .banner-txt{
                position: absolute;
                top: 40px;
                left: 55px;
                display: flex;
                align-items: center;
                height: 28px;
                padding: 0 18px;
                box-sizing: border-box;
                background: linear-gradient(90deg,#bc0000,rgba(188,0,0,.3));
                border-radius: 4px;
                color: #fff;
                font-size: 14px;
                font-weight: 600;
                line-height: 28px;
                .ant-statistic-content{
                    color: #fff000;
                    font-size: 14px;
                    margin-right: 3px;
                }
                
            }
            .banner-rules{
                position: absolute;
                right: 42px;
                top: 56px;
                height: 24px;
                padding: 0 14px;
                box-sizing: border-box;
                background: #000;
                border-radius: 12px;
                color: #fff;
                cursor: pointer;
                font-size: 13px;
                line-height: 24px;
                
            }
            .banner-rules:hover{
                background-color: #c00;
            }
        }
        
    }
    .invite-steps-bg{
        position: absolute;
        top: 407px;
        height: 220px;
        width: 100%;
        background: linear-gradient(180deg,#fac0c8,rgba(250,192,200,0)) no-repeat;
        background-size: 100% 220px;
    }
    .invite-steps{
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        z-index: 2;
        top: -1px;
        padding:30px 60px;
        box-sizing: border-box;
        background: #fff;
        .invite-steps-item{
            display: flex;
            align-items: center;
            .invite-steps-con{
                flex: 1;
                display: flex;
                align-items: center;
                .step-icon{
                    width: 68px;
                    margin-right: 20px;
                }
                .step-title{
                    color: #000;
                    font-size: 14px;
                    font-weight: 700;
                    margin-bottom: 7px;
                }
                .step-desc{
                    color: #000;
                    font-size: 14px;
                    .num{
                        margin: 0 3px;
                        color: #c00;
                    }
                }
            }
            .invite-process{
                width: 66px;
                margin:0 25px;
            }
        }
        
    }
    .my-invite{
        position: relative;
        top: -1px;
        z-index: 2;
        display: flex;
        padding: 0 50px;
        box-sizing: border-box;
        background-color: #fff;
        .invite-info-box{
            position: relative;
            flex: 1;
            height: 232px;
            padding: 20px 35px;
            box-sizing: border-box;
            border: 1px solid #ececec;
            border-radius: 5px;
            .bitfont{
                font-size: 20px;
                font-weight: 600;
                cursor: pointer;
            }
            .bitfont:hover{
                color: #c00;
            }
            .invite-info-title{
                margin-bottom: 15px;
                color: #000;
                font-size: 16px;
                font-weight: 600;
            }
            .invite-info-lab{
                display: flex;
                justify-content: space-between;
                .lab-txt{
                    color: #a8a8a8;
                    font-size: 14px;
                }
            }
            .invite-info-amount{
                display: flex;
                justify-content: space-between;
                margin-top: 10px;
                .num-txt{
                    color: #000;
                    font-size: 30px;
                    font-weight: 600;
                }
            }
            .invite-code{
                margin: 5px 0 15px;
                color: #000;
                font-size: 17px;
                font-weight: 600;
            }
            .invite-link{
                font-size: 14px;
            }
            .invite-btn{
                position: absolute;
                left: 35px;
                bottom: 20px;
                width: 280px;
                height: 30px;
                border-radius: 16px;
                font-size: 12px;
                line-height: 30px;
                color: #fff;
                font-weight: 600;
                text-align: center;
                cursor: pointer;
            }
        }
        .invite-info-box:first-child{
            margin-right: 25px;
        }
    }
    .content-box{
        position: relative;
        top: -1px;
        padding:30px 50px;
        box-sizing: border-box;
        background-color: #fff;
        .tabs-box-con{
            padding: 0 25px 25px;
            box-sizing: border-box;
            border: 1px solid #ececec;
            border-radius: 5px;
            .ant-table-cell{
                border: none;
            }
        }
        .rank-box{
            margin: 20px 0;
            padding: 20px 28px;
            box-sizing: border-box;
            border: 1px solid #ececec;
            border-radius: 5px;
            .rank-title{
                margin-bottom: 15px;
            }
            .ant-table-cell{
                border: none;
            }
        }
        .invite-description{
            padding: 20px 28px;
            box-sizing: border-box;
            border: 1px solid #ececec;
            border-radius: 5px;
            .invite-desc-header{
                display: flex;
                align-items: center;
                justify-content: space-between;
                .title{
                    display: flex;
                    align-items: center;
                    font-size: 15px;
                    font-weight: 600;
                    .icon{
                        display: inline-block;
                        height: 56px;
                        width: 56px;
                        background: url(../../assets/sprit1.png) no-repeat;
                        background-position: -25px -190px;
                        transform: scale(.5);
                    }
                }
                .btn{
                    min-width: 70px;
                    border: none;
                    border-radius: 12px;
                    font-size: 12px;
                    height: 23px;
                    line-height: 23px;
                    color: #fff;
                    font-size: 12px;
                }
            }
            .invite-header-desc{
                padding: 0 10px;
                margin-bottom: 22px;
                font-size: 12px;
                font-weight: 700;
                line-height: 30px;
            }
            .invite-ruler-title{
                box-sizing: border-box;
                margin-bottom: 20px;
                font-size: 16px;
                font-weight: 500;
            }
            .invite-ruler-item{
                margin-bottom: 15px;
                .invite-ruler-sub{
                    color: #000;
                    font-size: 12px;
                    line-height: 22px;
                    font-weight: 500;
                }
                .invite-ruler-desc{
                    padding: 0 10px;
                    box-sizing: border-box;
                    color: #000;
                    font-size: 12px;
                    line-height: 24px;
                }
            }
            

            .invite-coupon-list{
                display: flex;
                margin-bottom: 30px;
                border-bottom: 1px solid #ccc;
            }
        }
        
    }
}