.ls-tab{
    position: relative;
    z-index: 99;
    display: flex;
    align-items: center;
    height: 140px;
    margin: 0 auto;
    box-sizing: border-box;
    background: #fff;
    border-radius: 70px;
    box-shadow: 0 3px 20px 0 rgba(0,0,0,.06);
    .ls-tab-item{
        flex: 1;
        text-align: center;
        font-weight: 700;
        font-size: 14px;
        color: #333;
        cursor: pointer;
        .icon{
            position: relative;
            top: 0;
            width: 68px;
            height: 68px;
            margin: 0 auto;
            transition: top .3s;
            background-size: 100%;
            background-repeat: no-repeat;
        }
        .mining{
            background-image: url('../../../assets/nav-mining.png');
        }
        .rental{
            background-image: url('../../../assets/nav-rental.png');
        }
        .sales{
            background-image: url('../../../assets/nav-sales.png');
        }
        .hosting{
            background-image: url('../../../assets/nav-hosting.png');
        }
    }
    .ls-tab-item.active{
        position: relative;
    }
    .ls-tab-item.active::after{
        content: '';
        position: absolute;
        left: 50%;
        bottom: -27px;
        width: 100px;
        height: 6px;
        border-radius: 3px;
        transition: all .3s;
        transform:translateX(-50px);
        animation: ininTab 0.5s forwards;
        transition: all .2s ease-out;
    }
    .ls-tab-item.action-item{
        transition: all .2s ease-out;
    }
    .ls-tab-item.action-item:hover{
        transition: all .2s ease-out;
        transform: translateY(-5px);
    }
    .ls-tab-item.active{
        .mining{
            background-image: url('../../../assets/nav-mining.gif');
        }
        .rental{
            background-image: url('../../../assets/nav-rental.gif');
        }
        .sales{
            background-image: url('../../../assets/nav-sales.gif');
        }
        .hosting{
            background-image: url('../../../assets/nav-hosting.gif');
        }
    }
}

@keyframes ininTab {
    0%{
        background:transparent ;
    }
    100%{
        background: linear-gradient(90deg,#706f76,#010007);
    }
}