.app-download{
    overflow: hidden;
    position: relative;
    height: 600px;
    width: 100%;
    margin-top: 100px;
    .app-bg{
        overflow: hidden;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        .bgimg{
            position: absolute;
            left: 50%;
            height: 100%;
            transform: translateX(-50%);
        }
    }
    .app-content-box{
        position: relative;
        margin: 0 auto;
        .app-content{
            position: absolute;
            right: 106px;
            top: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            height: 100%;
            min-width: 530px;
            padding-top: 100px;
            text-align: center;
            z-index: 2;
            .app-tip{
                position: relative;
                display: inline-block;
                height: 28px;
                padding: 0 20px;
                background: linear-gradient(0deg,#c00,#eb0017);
                color: #fff;
                font-size: 14px;
                line-height: 28px;
                .tip-l{
                    position: absolute;
                    top: 0;
                    left: 0;
                    border-color: transparent transparent transparent #edeef4;
                    border-style: solid;
                    border-width: 14px 0 14px 7px;
                }
                .tip-r{
                    position: absolute;
                    right: 0;
                    top: 0;
                    border-color: transparent #edeef4 transparent transparent;
                    border-style: solid;
                    border-width: 14px 7px 14px 0;
                }
            }
            .app-title{
                margin-bottom: 30px;
                margin-top: 23.5px;
                font-family: Lato-Black;
                font-size: 34px;
                font-weight: 700;
                line-height: 21px;
            }
            .app-qrcode{
                display: flex;
                height: 178px;
                justify-content: center;
                width: 178px;
                align-items: center;
                margin: 0 auto 20px;
                background: linear-gradient(-70deg,#fff,#fff);
                border-radius: 8px;
                .qrcode{
                    width: inherit;
                }
            }
            .app-desc{
                width: 230px;
                margin: 0 auto;
                font-size: 16px;
                font-style: italic;
                line-height: 1.3;
                text-align: center;
            }
            .download{
                display: flex;
                justify-content: space-between;
                width: 420px;
                margin: 45px auto 0;
                .down-btn{
                    display: flex;
                    height: 56px;
                    justify-content: center;
                    align-items: center;
                    width: 190px;
                    border-radius: 30px;
                    cursor: pointer;
                    transition: transform .2s ease-in;
                    .down-img{
                        height: 32px;
                    }
                }
                .down-btn:nth-child(1){
                    background: linear-gradient(90deg,#343239,#010007);
                }
                .down-btn:nth-child(2){
                    border: 1.5px solid #000;
                }
                .down-btn:hover{
                    transform: scale(0.95);
                }
            }
        }
    }
    
}