.ls-footer{
    background: linear-gradient(-10deg,#26252c,#34323e);
    float: left;
    // height: 280px;
    position: relative;
    width: 100%;
    padding-bottom: 30px;
    .footer-box{
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        padding-top: 50px;
        margin: 0 auto;
        .f-left{
            width: 420px;
            .f-logo{
                width: 80px;
                cursor: pointer;
                .logo{
                    width: inherit;
                }
            }   
            .f-desc{
                margin:20px auto 30px;
                color: #fff;
                font-size: 14px;
            }
            .f-icon-list{
                display: flex;
                flex-flow: row;
                .icon-item{
                    width: 35px;
                    height: 35px;
                    margin-right: 16px;
                    .bitfont{
                        color: #fff;
                        font-size: 34px;
                    }
                }
            }
        }
        .f-right{
            display: flex;
            flex: 1;
            .f-right-item{
                flex: 1;
                .f-title{
                    color: #fff;
                    font-size: 16px;
                    font-weight: 500;
                }
                .f-link{
                    margin-top: 15px;
                    color: #767480;
                    cursor: pointer;
                    font-size: 14px;
                }
            }
        }
    }
    .f-copyright{
        margin: 20px auto 0;
        color: #767480;
        font-size: 13px;
    }
}