.tab-miner-bottom{
    display: flex;
    justify-content: center;
    align-items: center;
    .t-m-btn{
        position: relative;
        display: flex;
        width: 300px;
        height: 58px;
        padding-left: 52px;
        box-sizing: border-box;
        margin: 0 15px;
        background: #fff;
        border-radius: 29px;
        font-size: 14px;
        line-height: 58px;
        cursor: pointer;
        .txt{
            margin-right: 40px;
            font-weight: 700;
            .url{
                font-weight: 400;
            }
        }
        .bitfont{
            position: absolute;
            right: 25px;
            transition: right .3s;
            font-size: 14px;
            color: #999;
            font-weight: bold;
        }
    }
    .t-m-btn::before{
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        display: inline-block;
        height: 60px;
        width: 60px;
        transform: scale(.5);
        background: url(../../assets/sprit.png) no-repeat;
    }
    .t-icon1::before{
        background-position: -577px -473px;
    }
    .t-icon2::before{
        background-position: -660px -473px;
    }
    .t-m-btn:hover .bitfont{
        right: 20px;
        color: #000;
        transition: right .3s;
    }
}