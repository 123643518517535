.news-box-body{
    padding-bottom: 30px;
    box-sizing: border-box;
    .banner{
        width: 100%;
        margin: 30px 0;
    }
    .news-list{
        padding: 25px 25px 100px;
        box-sizing: border-box;
        background-color: #fff;
        .news-item{
            overflow: hidden;
            display: flex;
            align-items: center;
            height: 257px;
            padding: 46px 0;
            box-sizing: border-box;
            justify-content: space-between;
            border-bottom: 1px solid #e4e4e4;
            .news-item-cover{
                overflow: hidden;
                width: 292px;
                border-radius: 4px;
            }
            .news-item-right{
                flex: 1;
                margin-left: 50px;
                .news-item-title{
                    color: #000;
                    font-size: 22px;
                    font-weight: 700;
                    line-height: 26px;
                }
                .news-item-time{
                    margin: 14px 0;
                    color: #a0a0a0;
                    font-size: 16px;
                    line-height: 16px;
                }
                .news-item-desc{
                    color: #000;
                    font-size: 16px;
                    line-height: 24px;
                }
                .ant-typography{
                    margin-bottom: 0;
                }
            }
        }
    }
    .more-news{
        width: 100%;
        height: 50px;
        background-color: #f8f8f8;
        color: #333;
        cursor: pointer;
        font-size: 14px;
        line-height: 50px;
        text-align: center;
    }
}