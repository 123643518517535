.register-modal{
    display: flex;
    justify-content: space-between;
    .login-bg{
        width: 300px;
    }
    .login-content{
        padding:30px 50px;
        .login-title{
            margin-bottom: 30px;
            font-size: 24px;
            font-weight: 500;
            color: #0c0c0e;
        }
        .ipt-lab{
            margin-bottom: 5px;
            font-size: 14px;
            font-weight: 500;
        }
        .ipt-lab1{
            padding:15px 0 5px;
            font-size: 14px;
            cursor: pointer;
            .bitfont{
                position: relative;
                top: 2px;
                display: inline-block;
                transform: rotate(180deg);
                transition: all 0.3s;
            }
            .bitfont.bithide{
                transform: rotate(0deg);
                transition: all 0.3s;
            }
        }
        .code-box{
            overflow: hidden;
            height: 40px;
            transition: all 0.3s;
        }
        .code-box.code-box-hide{
            height: 0;
            transition: all 0.3s;
        }
        .login-check{
            margin-top: 20px;
        }
        .connect-btn{
            margin: 15px 0 10px;
            font-weight: 500;
        }
        .login-check{
            font-size: 12px;
            .check{
                margin-right: 8px;
            }
            
        }
        .login-continue{
            margin: 15px 0 10px;
            font-size: 16px;
            .address{
                overflow-x: auto;
                width: 300px;
            }
            .dis{
                margin-left: 5px;
                font-size: 12px;
            }
        }
        .login-footer{
            text-align: center;
            .sign-up{
                color: #c00;
                font-size: 14px;
            }
            .sign-up:hover{
                color: rgb(178, 21, 21);
            }
        }
    }
}