.tab-content{
    padding: 50px 0;
    margin: 0 auto;
    box-sizing: border-box;
    text-align: center;
    .tab-tips{
        margin-bottom: 30px;
        font-size: 15px;
        line-height: 25px;
        text-align: center;
    }
    .goods-box{
        display: flex;
        justify-content: center;
        .good-item-box{
            margin: 0 15px;
        }
    }
    .more-btn{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 140px;
        height: 46px;
        margin: 50px auto 0;
        border: 1.5px solid rgba(52,50,57,.1);
        border-radius: 23px;
        color: #000;
        cursor: pointer;
        font-size: 14px;
        font-weight: 500;
        transition: all .2s ease-out;
        .bitfont{
            transition: all .2s ease-out;
        }
    }
    .more-btn:hover{
        color: #c00;
        border: 1px solid #c00;
        .bitfont{
            transition: all .2s ease-out;
            transform: translateX(10px);
        }
    }
}